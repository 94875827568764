import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { StoryPageQuery } from '../../types/graphql'

import {
  Blanket,
  BlanketTitle,
  BlanketBackground,
  BlanketContent,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { Media } from '../../components/Media'
import { SEO } from '../../components/Seo'

import { parse } from '../../utils/parse'

import * as classes from './story-page.module.scss'

interface Props {
  data: StoryPageQuery
}

export default ({ data }: Props) => (
  <Blanket>
    <SEO title={`La Storia - ${data.wordpressPost?.slug}`} />

    <Container>
      <Media
        data={
          data.wordpressPost?.featured_media?.localFile?.childImageSharp
            ?.fluid as FluidObject
        }
        imageClassName={classes.cover}
      />
    </Container>

    <BlanketBackground>
      <Container size={'small'}>
        <BlanketTitle>{data.wordpressPost?.slug}</BlanketTitle>

        <BlanketContent>{parse(data.wordpressPost?.content!)}</BlanketContent>
      </Container>
    </BlanketBackground>
  </Blanket>
)

export const query = graphql`
  query StoryPage($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      id
      slug
      title
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1080, maxHeight: 1080, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
